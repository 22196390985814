import { BookingRequest } from '@camp67/model';
import { PriceComputation } from '@camp67/model/booking/price';

export const initialBookingRequest: BookingRequest = {
  primaryContact: {
    id: 'initial-id',
    name: '',
    email: '',
    phone: '',
    acceptedConditions: false,
  },
  reservation: {
    startDate: '',
    endDate: '',
    guests: {
      adult: 1,
      children: 0,
      details: [],
    },
    rooms: [],
  },
};

export const initialPrice: PriceComputation = {
  nights: 0,
  nightsBathing: 0,
  total: 0,
  totalFromBathingDays: 0,
  totalFromNonBathingDays: 0,
  totalGst: 0,
  totalWithGst: 0,
};
