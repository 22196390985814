import { TentTypeV1, getDateString } from '@camp67/model';
import { environmentState } from '../state/environment';
import { retry } from '../retry';
import { propagateError } from '../propagate-error';

export async function queryTentAvailability(startDate: string, endDate: string) {
  const base = environmentState.apiUrl;
  const url = new URL(base);
  url.pathname += 'api/reservation/availability';

  const response = await retry('query-availability', (signal) =>
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        startDate: getDateString(startDate),
        endDate: getDateString(endDate),
      }),
      credentials: 'include',
      signal,
    }),
  );

  if (!response.ok) {
    const error = new Error(response.statusText);
    propagateError(error, 'tent availability');

    throw error;
  }

  const tents = await response.json<TentTypeV1[]>();
  return tents;
}

type RequestDateAvailabilityOpts = {
  rooms: [TentTypeV1, number][];
};

export async function queryTentAvailabilityDates(opts: RequestDateAvailabilityOpts) {
  const base = environmentState.apiUrl;
  const url = new URL(base);
  url.pathname += 'api/reservation/availability/dates';

  const response = await retry('query-availability-dates', (signal) =>
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(opts),
      credentials: 'include',
      signal,
    }),
  );

  if (!response.ok) {
    const error = new Error(response.statusText);
    propagateError(error, 'tent availability dates');

    throw error;
  }

  const dates = await response.json<string[]>();
  return dates;
}
