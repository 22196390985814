export const nightsBetween = (startDate: string, endDate: string) => {
  // Parse the startDate and endDate strings into Date objects
  const checkInDate = new Date(startDate);
  const checkOutDate = new Date(endDate);

  // Calculate the difference in milliseconds between the two dates
  const differenceInMilliseconds = checkOutDate.getTime() - checkInDate.getTime();

  // Convert the difference from milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Return the number of nights as the difference in days
  return Math.floor(differenceInDays);
};

const removeDashes = (str: string) => str.replace(/-/g, ' ');

export const toSentenceCase = (str: string) =>
  removeDashes(str[0].toUpperCase() + str.slice(1));

export const pluralise = (n: number, s: string) => {
  return `${s}${n !== 1 ? 's' : ''}`;
};
